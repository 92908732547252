import { Card as HeroUiCard, CardBody, CardFooter, CardHeader, Divider, extendVariants } from '@heroui/react';
import { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    children: React.ReactNode;
    classNames?: ComponentProps<typeof HeroUiCard>['classNames'] & {
        divider?: string;
    };
    header?: React.ReactNode;
    footer?: React.ReactNode;
    dividers?: { afterHeader?: boolean; afterBody?: boolean };
};

const ExtendedCard = extendVariants(HeroUiCard, {
    variants: {
        shadow: {
            card: {
                base: 'shadow-[0_2px_10px_0_rgba(0,0,0,0.1)]',
            },
        },
    },
});

export function Card({
    children,
    classNames,
    header,
    footer,
    dividers = { afterHeader: false, afterBody: false },
}: Props) {
    return (
        <ExtendedCard
            classNames={{
                base: twMerge('bg-white border border-solid border-slate-grey-200 rounded-8', classNames?.base),
                header: twMerge('p-3.5', classNames?.header),
                body: twMerge('p-3.5', classNames?.body),
                footer: twMerge('p-3.5', classNames?.footer),
            }}
            shadow="card"
        >
            {header && <CardHeader>{header}</CardHeader>}
            {dividers.afterHeader && <Divider className={classNames?.divider} />}
            <CardBody>{children}</CardBody>
            {dividers.afterBody && <Divider className={classNames?.divider} />}
            {footer && <CardFooter>{footer}</CardFooter>}
        </ExtendedCard>
    );
}
