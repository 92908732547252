const config = {
    build_number: '600492',
    build_timestamp: '2025-04-03T15:37:32.872Z',
    build_commit: 'a287d2943a7bd11db81f96285a433f721e911d76',
};

// locally, the build number is always 4122 (which allows us to load non-English content locally)
if (Number.isNaN(parseInt(config.build_number, 10))) {
    config.build_number = '4122';
}

export default config;
