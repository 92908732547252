import cacheAngularTemplate from 'cacheAngularTemplate';
import angularModule from './careers_module';
import template from '../views/edit_career_profile/education_form.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('educationForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const Cohort = $injector.get('Cohort');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                scope.helper = EditCareerProfileHelper;
                FormHelper.supportForm(scope, scope.education);

                scope.helper.logEventForApplication('viewed-education');

                // These manage the legal_names input to the education form. Presently, the hard-coded limit of legal_names in the array is 5.

                scope.disableEditLegalNameItem = item => item === scope.currentUser.name;

                scope.$watchCollection('careerProfile.legal_names', legalNames => {
                    if (!legalNames.includes(scope.currentUser.name)) {
                        scope.careerProfile.legal_names.push(scope.currentUser.name);
                    }
                });

                scope.maxLegalNames = 5;

                Object.defineProperty(scope, 'showHasNoFormalEducationCheckbox', {
                    get() {
                        return (
                            !!scope.careerProfile &&
                            !scope.formalEducationIsRequired &&
                            !scope.careerProfile.formalEducationExperiences?.length &&
                            !scope.helper.highestLevelEducationRequiresFormalEducation(scope.careerProfile)
                        );
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'formalEducationIsRequired', {
                    get() {
                        return scope.helper.highestLevelEducationRequiresFormalEducation(scope.careerProfile);
                    },
                    configurable: true,
                });

                scope.$watch('formalEducationIsRequired', formalEducationIsRequired => {
                    if (formalEducationIsRequired) {
                        scope.careerProfile.addDummyEducationExperienceIfNecessary();
                    } else if (scope.careerProfile.hasLoneEmptyFormalEducationExperience) {
                        const index = scope.careerProfile.education_experiences.findIndex(ee => ee.isDegreeProgram);
                        scope.careerProfile.education_experiences.splice(index, 1);
                    }
                });

                scope.$watchCollection('careerProfile.education_experiences', () => {
                    if (!scope.showHasNoFormalEducationCheckbox) {
                        scope.careerProfile.has_no_formal_education = false;
                    }
                });

                // special messaging for cohorts that support document upload
                scope.willProvideDocuments =
                    Cohort.supportsDocumentUpload(scope.currentUser.relevantCohort?.programType) &&
                    scope.helper.isApplication();

                scope.englishLangueProficiencyFieldValueConfigs = [
                    { value: true, localeKey: 'yes' },
                    { value: false, localeKey: 'no' },
                ];

                scope.highestLevelEducationOptions = scope.helper.highestLevelEducationOptions();

                scope.englishLanguageProficiencyFieldConfigs = [
                    {
                        modelProp: 'native_english_speaker',
                        inputType: 'radio',
                        fieldIsVisible() {
                            return true;
                        },
                        shouldRemoveFormGroupBorder() {
                            return !!isNextFieldVisible('earned_accredited_degree_in_english');
                        },
                    },
                    {
                        modelProp: 'earned_accredited_degree_in_english',
                        inputType: 'radio',
                        fieldIsVisible() {
                            return scope.careerProfile.native_english_speaker === false;
                        },
                        showSubtext() {
                            return (
                                scope.careerProfile.earned_accredited_degree_in_english === false &&
                                !isNextFieldVisible('sufficient_english_work_experience')
                            );
                        },
                        shouldRemoveFormGroupBorder() {
                            return !!isNextFieldVisible('sufficient_english_work_experience');
                        },
                    },
                ];

                function isNextFieldVisible(modelProp) {
                    return _.find(
                        scope.englishLanguageProficiencyFieldConfigs,
                        fieldConfig => fieldConfig.modelProp === modelProp && fieldConfig.fieldIsVisible(),
                    );
                }

                // for data integrity purposes
                scope.$watch('careerProfile.native_english_speaker', () => {
                    if (scope.careerProfile.native_english_speaker) {
                        scope.careerProfile.earned_accredited_degree_in_english = null;
                    }
                });
            },
        };
    },
]);
