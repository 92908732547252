import { type ButtonProps as HeroUiButtonProps, Button as HeroUiButton, extendVariants } from '@heroui/react';
import clsx from 'clsx';
import { forwardRef, memo, useMemo, type ComponentProps, type FC } from 'react';
import { twMerge } from 'tailwind-merge';

const ExtendedButton = extendVariants(HeroUiButton, {
    variants: {
        color: {
            black: 'bg-black text-white',
            white: 'bg-white text-black',
            'primary-gradient': clsx(
                'bg-gradient-to-r from-coral to-orange-500-alt text-white',
                'valar:from-blue-500-alt valar:to-blue-400-alt',
                'blue-frame:from-blue-500-alt blue-frame:to-blue-400-alt',
                'purple-frame:from-purple-500-alt purple-frame:to-purple-450-alt',
                'turquoise-frame:from-turquoise-600-alt turquoise-frame:to-turquoise-500-alt',
            ),
        },
    },
});

// I'd rather define the type here than export ExtendedButton into a type file
// eslint-disable-next-line quantic/no-type-exports
export type ButtonProps = { color?: HeroUiButtonProps['color'] | 'white' | 'black' | 'primary-gradient' } & Omit<
    ComponentProps<typeof ExtendedButton>,
    'color'
>;

const showBorder = (variant = '') => ['bordered', 'ghost', 'faded'].includes(variant);

export const Button: FC<ButtonProps> = memo(
    forwardRef(({ children, color = 'default', className, variant, radius = 'sm', ...rest }, ref) => {
        const buttonClass = useMemo(
            () => twMerge(showBorder(variant) ? 'border-1' : null, className),
            [className, variant],
        );

        return (
            <ExtendedButton ref={ref} className={buttonClass} color={color} variant={variant} radius={radius} {...rest}>
                {children}
            </ExtendedButton>
        );
    }),
);
