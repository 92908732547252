import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { type FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
    type RequiredProfilesForSendbirdBatchedResponse,
    type CareerProfile,
    type RecommendedClassmatesData,
    type RecommendedClassmatesResponse,
} from './CareerProfiles.types';

export const careerProfilesApi = createApi('careerProfilesApi', {
    ...getBackRoyalApiBaseConfig('careerProfilesApi'),
    // eslint-disable-next-line max-lines-per-function
    endpoints: builder => ({
        getRecommendedClassmates: builder.query<RecommendedClassmatesData[], void>({
            query: () => 'career_profiles/recommended_classmates.json',
            transformResponse: (response: RecommendedClassmatesResponse) =>
                response.contents.careerProfiles?.map(({ name, avatarUrl, workExperiences, userId, ...rest }) => ({
                    name,
                    avatarUrl,
                    userId,
                    jobTitle: workExperiences?.[0]?.jobTitle,
                    company: workExperiences?.[0]?.professionalOrganization?.text,
                    workExperiences,
                    ...rest,
                })) || [],
            keepUnusedDataFor: 30 * 60,
        }),
        getProfileForSendbirdById: builder.query<CareerProfile, string>({
            query: userId => ({
                url: 'career_profiles.json',
                params: {
                    filters: {
                        userIds: [userId],
                        sendbirdUserIds: true,
                        only: true,
                    },
                    view: 'student_network_profiles',
                },
            }),
            transformResponse: (response: { contents: { careerProfiles: CareerProfile[] } }, _meta, userId) =>
                // If currentUser is an admin, it returns this user plus all of the other admins, so we need to find instead of using careerProfiles[0]
                response.contents.careerProfiles.find(cp => cp.userId === userId)!,
        }),
        getAllRequiredProfilesForSendbirdInBatches: builder.query<CareerProfile[], string[]>({
            queryFn: async (userIds, _api, _options, fetchWithBasQuery) => {
                const profiles: CareerProfile[] = [];
                let offset = 0;
                let hasMore = true;

                try {
                    while (hasMore) {
                        // We are awaiting in a loop to purposefully execute these requests
                        // synchronously to minimize potential server issues by
                        // creating so many ruby objects at one time
                        // eslint-disable-next-line no-await-in-loop
                        const response = await fetchWithBasQuery({
                            url: 'career_profiles/index.json',
                            method: 'POST',
                            body: {
                                filters: { userIds, sendbirdUserIds: true, only: true },
                                offset,
                                limit: 25,
                                view: 'student_network_profiles',
                            },
                        });

                        if (response.error) throw response.error;

                        const { contents, meta } = response.data as RequiredProfilesForSendbirdBatchedResponse;
                        profiles.push(...(contents?.careerProfiles || []));
                        offset = profiles.length;
                        hasMore = offset < meta.totalCount;

                        if (hasMore) {
                            // eslint-disable-next-line no-await-in-loop
                            await new Promise(r => {
                                setTimeout(r, 25);
                            });
                        }
                    }

                    return { data: profiles };
                } catch (error) {
                    return { error: error as FetchBaseQueryError };
                }
            },
        }),
        searchForSendbirdProfilesByUserName: builder.query<CareerProfile[], string>({
            query: nameText => ({
                url: 'career_profiles.json',
                params: {
                    filters: {
                        nameText,
                    },
                    limit: 20,
                    view: 'student_network_profiles',
                },
            }),
            transformResponse: (response: { contents: { careerProfiles: CareerProfile[] } }) =>
                response.contents?.careerProfiles || [],
            keepUnusedDataFor: 30 * 60,
        }),
    }),
});
