/* eslint-disable func-names */
import angularModule from 'Careers/angularModule/scripts/careers_module';
import { getFormsCompletePercent } from 'FormCompletionHelper';
import { StudentProfileFormConfigs } from 'FormConfigs';
import { initializeEvent, processForThirdParties } from 'TinyEventLogger';
import sha256 from 'sha256';

angularModule.service('EditCareerProfileHelper', [
    '$injector',
    function ($injector) {
        const $rootScope = $injector.get('$rootScope');
        const EventLogger = $injector.get('EventLogger');
        const ErrorLogService = $injector.get('ErrorLogService');
        const TranslationHelper = $injector.get('TranslationHelper');
        const fieldOptionTranslationHelper = new TranslationHelper('careers.field_options');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $q = $injector.get('$q');
        const $location = $injector.get('$location');

        // --------------------------
        // Tests for which context the user is filling out a profile in
        // --------------------------

        this.isApplication = function () {
            return $location.path() === '/settings/application';
        };

        this.isApplicantEditor = function () {
            return $location.path() === '/admin/users';
        };

        this.isProfile = function () {
            return $location.path() === '/settings/my-profile';
        };

        this.getCompletionPercentage = function (user, careerProfile, programFamilyFormDatum) {
            if (this.isProfile() && careerProfile.completedAt) return 100;

            const steps = this.isProfile() ? StudentProfileFormConfigs : user.relevantCohort.applicationFormConfigs;
            // careerProfile and programFamilyFormDatum are proxies coming from EditCareerProfile that we use
            // to track completion BEFORE saving anything to the database.
            return getFormsCompletePercent(user, steps, careerProfile, programFamilyFormDatum);
        };

        // --------------------------
        // Tests for which features are supported/required for the profile
        // --------------------------

        this.highestLevelEducationRequiresFormalEducation = careerProfile => {
            const highestLevelEducation =
                careerProfile && careerProfile.survey_highest_level_completed_education_description;
            return !!highestLevelEducation && highestLevelEducation !== 'high_school';
        };

        // --------------------------
        // Shared select field options
        // --------------------------

        this.recentRolesOptions = careerProfile => {
            const currentRoleValue = careerProfile.survey_most_recent_role_description;
            const roleKeys = $injector.get('CAREERS_RECENT_ROLE_KEYS');
            /* Some career profiles may have the deprecated role values `administrative_role` or `skilled_labor_role`.
               We will include their existing value in the recent-role dropdown in those cases -- https://trello.com/c/J5h4xBE8 */
            const hasLegacyValue = ['administrative_role', 'skilled_labor_role'].includes(currentRoleValue);
            const optionKeysToUse = hasLegacyValue ? roleKeys.concat(currentRoleValue) : roleKeys;
            return mapKeysToOptions(optionKeysToUse, false);
        };

        this.highestLevelEducationOptions = () =>
            $injector.get('CAREERS_HIGHEST_EDUCATION_KEYS').map(key => ({
                label: fieldOptionTranslationHelper.get(key),
                value: key,
            }));

        this.yearsExperienceOptions = () => mapKeysToOptions($injector.get('CAREERS_FULL_TIME_EXPERIENCE_KEYS'));

        function mapKeysToOptions(keys, sort) {
            const options = keys.map(key => ({
                label: fieldOptionTranslationHelper.get(key),
                value: key,
            }));

            if (sort) {
                options.sort((a, b) =>
                    a.label.localeCompare(b.label, fieldOptionTranslationHelper.getCurrentLanguage()),
                );
            }
            return options;
        }

        // --------------------------
        // Event logging helper
        // --------------------------

        this.logEventForApplication = function (eventName, force, payload = {}) {
            const user = $rootScope.currentUser;

            if (!user) throw new Error('No $rootScope.currentUser');

            if (!eventName)
                throw new Error('Calling edit_career_profile_helper#logEventForApplication without an eventName');

            if (this.isApplication() || force) {
                const type = `application-form:${eventName}`;

                const _payload = _.extend(
                    {
                        program_type: user.programType,
                        log_to_customerio: true, // Useful for application abandonment campaigns in Customer.io
                    },
                    payload,
                );

                if (eventName === 'submit-application') {
                    const options = {
                        serverTimeOrConfigFactory: ConfigFactory,
                    };

                    $q.all([sha256(user.email), sha256(user.phone)])
                        .then(sha256Values => {
                            _payload.sha256_email_address = user.email && sha256Values[0];
                            _payload.sha256_phone_number = user.phone && sha256Values[1];

                            // `TinyEventLogger.initializeEvent` will try to find serverGeneratedTimestamp
                            // on the window if we don't pass in `options.serverTimeOrConfigFactory`. On
                            // Cordova, we do not have a `window.serverGeneratedTimestamp`, which will cause
                            // `initializeEvent` to throw an error. We need to pass ConfigFactory into `initializeEvent`.
                            const event = initializeEvent(type, _payload, options);
                            processForThirdParties(event);
                        })
                        .catch(e => ErrorLogService.notify(e.message));

                    // This event will be logged from the server, so skip logging with EventLogger
                    return;
                }

                EventLogger.log(type, _payload);
            }
        };
    },
]);
