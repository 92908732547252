/* eslint-disable consistent-return */
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';
import angularModule from '../careers_module';
import template from '../../views/edit_career_profile/basic_info_form.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('basicInfoForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const dateHelper = $injector.get('dateHelper');
        const LocalizedCountries = $injector.get('LocalizedCountries');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                scope.helper = EditCareerProfileHelper;
                scope.dateHelper = dateHelper;

                // FIXME: account for EMBA as well
                scope.helper.logEventForApplication('viewed-basic_info');

                scope.countryOptions = _.map(LocalizedCountries.getForCurrentLocale(), country => ({
                    value: country.value,
                    label: country.text,
                }));

                scope.recentRolesOptions = scope.helper.recentRolesOptions(scope.careerProfile);
                scope.highestLevelEducationOptions = scope.helper.highestLevelEducationOptions();
                scope.yearsExperienceOptions = scope.helper.yearsExperienceOptions(scope.careerProfile);

                scope.$watch('careerProfile.place_details', placeDetails => {
                    if (!_.some(placeDetails)) return;

                    // Note that we only set these if the user has not explicity filled out their mailing address so as not
                    // to overrite their explicit values with inferred values from the Google Place
                    if (!scope.careerProfile.address_line_1) {
                        scope.careerProfile.city = scope.careerProfile.placeCity;
                        scope.careerProfile.state = scope.careerProfile.placeState;
                        scope.careerProfile.country = scope.careerProfile.placeCountry;
                    }
                });

                FormHelper.supportForm(scope, scope.basic_info);

                scope.minBirthday = moment().subtract(100, 'years').format('YYYYMMDD');
                scope.maxBirthday = moment().subtract(13, 'years').format('YYYYMMDD');
                scope.proxy = {};

                scope.$watch('proxy.birthdateViewValue', () => {
                    if (scope.proxy.birthdateViewValue) {
                        scope.invalidAge =
                            scope.proxy.birthdateViewValue < scope.minBirthday ||
                            scope.proxy.birthdateViewValue > scope.maxBirthday
                                ? moment().diff(scope.proxy.birthdateViewValue, 'years')
                                : undefined;
                    } else {
                        scope.invalidAge = undefined;
                    }

                    scope.showInvalidAge = angular.isDefined(scope.invalidAge);
                });

                scope.$on('beforeSaveForm', () => {
                    if (!scope.careerProfile.legal_names.includes(scope.careerProfile.name)) {
                        scope.careerProfile.legal_names.push(scope.careerProfile.name);
                    }
                });

                const fieldOptionsTranslationHelper = new TranslationHelper('careers.field_options');
                const demoGraphicsTranslationHelper = new TranslationHelper(
                    'careers.edit_career_profile.demographics_form_section',
                );

                scope.genderPlaceHolder = demoGraphicsTranslationHelper.get('gender');

                scope.sexOptions = ['male', 'female', 'non_binary', 'self_describe', 'no_identify'].map(
                    translationKey => ({
                        value: translationKey,
                        label: fieldOptionsTranslationHelper.get(translationKey),
                    }),
                );

                scope.sexPlaceholder = scope.careerProfile?.sex
                    ? ['male', 'female', 'non_binary', 'no_identify'].find(v => v === scope.careerProfile.sex) ||
                      'self_describe'
                    : null;

                scope.isSelfDescribedSex = scope.sexPlaceholder === 'self_describe';

                scope.$watch('sexPlaceholder', (newVal, oldVal) => {
                    if (newVal === oldVal) return;

                    if (newVal === 'self_describe') {
                        scope.careerProfile.sex = null;
                        scope.isSelfDescribedSex = true;
                        return;
                    }

                    scope.isSelfDescribedSex = false;
                    scope.careerProfile.sex = newVal;
                });

                scope.$watch('careerProfile.sex', val => {
                    if (!val) scope.careerProfile.sex = null;
                });
            },
        };
    },
]);
