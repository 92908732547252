import { Chip } from '@heroui/react';
import clsx from 'clsx';
import { type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    children: React.ReactNode;
    size?: ComponentProps<typeof Chip>['size'];
    variant?: ComponentProps<typeof Chip>['variant'];
    radius?: ComponentProps<typeof Chip>['radius'];
    classNames?: ComponentProps<typeof Chip>['classNames'];
    startContent?: React.ReactNode;
    endContent?: React.ReactNode;
    avatar?: React.ReactNode;
    onClose?: ComponentProps<typeof Chip>['onClose'];
};

const sizeClasses = (size: Props['size']) =>
    clsx({
        'text-xxs': size === 'sm',
        'text-xs': size === 'md',
        'text-md': size === 'lg',
    });

export function Capsule({
    children,
    size = 'md',
    variant = 'solid',
    radius = 'full',
    classNames,
    startContent,
    endContent,
    avatar,
    onClose,
}: Props) {
    return (
        <Chip
            classNames={{
                base: twMerge('bg-slate-grey-100', sizeClasses(size), classNames?.base),
                content: twMerge('text-black font-medium px-2.5 py-1.5', sizeClasses(size), classNames?.content),
            }}
            size={size}
            variant={variant}
            radius={radius}
            startContent={startContent}
            endContent={endContent}
            avatar={avatar}
            onClose={onClose}
        >
            {children}
        </Chip>
    );
}
